import React from 'react';
import './Memorial.css'; // Make sure to create this CSS file

function Memorial() {
  return (
    <div className="container">
      <h1>Memorial</h1>
      <div className="images-container">
        <img src="/images/IMG_1024.JPG" alt="Leonard" className="memorial-image" />
        <img src="/images/IMG_1298.JPG" alt="Leonard and Friends" className="memorial-image" />
      </div>
      <p>Leonard George Alborn.</p>
      <p>
        I met Leonard in September of 1974, when Albright College put us in a Court dormitory suite together 
        (with 2 other guys) as roommates. We shared a love of football, baseball, Philadelphia sports, card playing, 
        food, and drinking (I may not have the order correct there), and we became great friends very quickly.
      </p>
      <p>
        In October of 1975, Lenny (we’ll get into the nicknames in a minute) decided he was going to pledge a frat, 
        Zeta Omega Epsilon, and for some crazy reason, I decided to join him. The three weeks of pledging together – 
        just the two of us – brought us even closer together. We also fit in pretty well with the other 21 idiots in 
        the frat at the time, including Paul and Mark, who had pledged the previous spring. Those friendships, and 
        the bonds formed at the time, grew even closer over the next 43 years.
      </p>
      <p>
        His family, and Carole’s family, all called him Leonard. That was too formal for us in 1974, so we called him 
        Len or Lenny. One of the older guys in the frat thought he resembled (in both looks and mannerisms) a guy who 
        was nicknamed Mahooba (not sure why, I never met the older guy). But the nickname stuck, although it was 
        fairly quickly changed to just Hoobie. Because for those of us who knew him best, it wasn’t the resemblance 
        to a former frat brother – ‘Hoobie’ was Lenny. When you say the name out loud and conjure up a thought, it’s 
        hard not to think of his demeanor (“Do I look like I give a shit?”), actions (the definition of ‘laid back’), 
        hand gestures (“Scoop, Joop!”), and speech (“’Enever”, not “whenever”, and hundreds more too numerous to list).
      </p>
      <p>
        For the Derby, he was a three-time Derby Champion, winning the first two in 1985 and 1986, and then 
        miraculously again in 2013, in a scotch-soaked haze in the Derby held at the Philadelphia Airport Embassy 
        Suites. Famous for his “Who did he pick?” line, followed almost immediately by “He’s not even on my list!”, 
        he brought countless hours of humor to the Derby.
      </p>
      <p>
        Our great friend tragically passed away in March of 2018. One of the greatest tributes came from one of his 
        students who attended his memorial service. He told us of a fellow student asking Hoobie some favor or 
        request in regards to an assignment. In Hoobie’s mind, that was obviously a pretty ridiculous request, 
        because he said to the student who asked, “Oh, I guess I forgot to take my ‘I give a shit’ pills today.” Not 
        mean, not hurtful, just factual. That was Hoobie. ‘I’m your teacher, you can ask, but if it’s a ridiculous 
        question, you’re gonna get an answer that you may not like. Because at this point in my career … I just don’t 
        give a shit.’
      </p>
      <p>
        We honor him by keeping him involved in the Derby, picking a row for him every year, because he was such a 
        huge part of the Derby experience. And our lives.
      </p>
      <p>
        Rest in peace Leonard, Len, Lenny … Hoobie.
      </p>
    </div>
  );
}

export default Memorial;
