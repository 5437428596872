import React from 'react';

const Home = () => {
  return (
    <div className="container">
      <h1>Letter from the Commissioner</h1>
      <p>Viva! Welcome to the official Homerun Derby website!!!</p>
      <p>
        It was April 1972. The Pittsburgh Pirates, led by Roberto Clemente and Willie Stargell, were the
        World Champions of baseball. The Phillies stunk (although Steve Carlton would have one of
        the best seasons in baseball history for one of the worst teams). The Yankees were also not
        that good at the time, despite their rich history. The Oakland A’s dynasty (3 World Series titles
        in 3 years) was about to begin. And seven teachers at Mendham High School got together for
        the first ever Homerun Derby. A small, quiet affair, and 205 homeruns won that year. But it
        quickly grew in popularity … 10 teams in 1973, 12 in 1974, and eventually to 16 teams the year
        my brother Doug (who started teaching at Mendham in September 1979) joined in the festivities
        in April 1980. I was invited to come along for the 1981 Derby. The Mendham Derby was
        something special … held at Beavers Stadium (not Penn State, but Doug Beavers house
        (driveway, garage, and basement)) … kegs (and soft drinks – there WERE teachers there),
        London broils (we got up to 6 some years, if I remember correctly), piano interludes … Homerun
        Derby in the early 1980’s was something special.
      </p>
      <p>
        Those days, back at Doug’s and my house, I kept a daily tally sheet hanging on our kitchen wall,
        and updated it as best I could, with daily box scores, and the statistics that were published in the
        Sunday Star Ledger Sports section. Paul, Mark, and Hoobie would all come by at various
        points for various parties, etc., and we’d talk about Homerun Derby. Sounds like fun we said.
        We should do something like that, we reacted. So we did.
      </p>
      <p>
        On Saturday, April 6, 1985, eight people, making up seven teams, got together in the dining
        room of 41 Sunset Trail in Denville, New Jersey, to hold the first Denville Derby. Brother Doug,
        Hoobie, Paul, Mark and I are the remaining original Derby participants (Brother Barry joined us
        in year 2). With so few teams, we did 12 rounds in the early days, with the best 10 of 12
        counting, and remarkably, Hoobie won that first Derby, with a total of 248. We’ve had as few as
        6 teams (in 1997, with the best 13 of 15 rounds), and many as 10 teams (best 8 of 10, in 1991).
      </p>
      <p>
        And then tragedy struck the Derby in March of 2018, when Hoobie passed away. Hoobie
        brought countless hours of humor and fun to the Derby, and his loss was felt deeply by us all.
        There actually was no Derby that year, with Hoobie being declared the honorary 2018 Derby
        Champion. We reconvened in 2019, in Las Vegas (the 35th anniversary), but it wasn’t the same
        without him, and whispers of the discontinuing the Derby were heard.
      </p>
      <p>
        I’m not sure who came up with the idea to include “the kids,” but it was a great idea. So in 2020,
        with Covid running rampant around the country, which forced the baseball season to be
        postponed until July, we had our first Derby with young Douglas, Jeremy, Anthony, Brian, and
        Freddie. The kids have reinvigorated the Derby. And a special thanks and shout out to Freddie,
        who has put together this website, so that the Derby can be enjoyed by us all. Current
        standings and statistics, sidebets, past history, humorous anecdotes, tributes, and more, can all
        be found here on the website. Really an outstanding job Freddie … we can’t thank you enough!
      </p>
      <p>
        Enjoy the website, and feel free to make any suggestions of things you may want to see or
        improvements that could be made. We’ll try to make them happen.
      </p>
      <p>
        Time for a social drink … Viva! Long Live The Derby!!!
      </p>
      <p>
        The Commissioner
      </p>
    </div>
  );
};

export default Home;