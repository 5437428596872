import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, Navigate } from 'react-router-dom'; // Add Link here
import axios from 'axios';  // Import axios
import Standings from './components/Standings';
import Derby from './components/Derby';
import Injury from './components/Injury';
import Records from './components/Records';
import PastWinners from './components/PastWinners';
import Memorial from './components/Memorial';
import Footer from './components/Footer';
import Home from './components/Home';
import './App.css';  // Importing App.css

/*
 * Developer Information:
 * -----------------------
 * Developed by: Freddie Main III
 * Contact: FMain89@gmail.com
 */

function RedirectWithoutTrailingSlash() {
  let location = useLocation();

  // Check if the location pathname ends with a slash and is not the root
  if (location.pathname.length > 1 && location.pathname.endsWith('/')) {
    return <Navigate to={location.pathname.slice(0, -1) + location.search} replace />;
  }

  return null;
}

function App() {
  const [injuredPlayers, setInjuredPlayers] = useState([]);

  useEffect(() => {
    const fetchInjuredPlayers = async () => {
      try {
        const response = await axios.get('https://lw2pwsj2ye.execute-api.us-east-1.amazonaws.com/dev/api/injuries');
        setInjuredPlayers(response.data);
      } catch (error) {
        console.error('Error fetching injured players:', error);
      }
    };

    fetchInjuredPlayers();
  }, []);

  return (
    <Router>
      <RedirectWithoutTrailingSlash />
      <div>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/standings">Standings</Link></li>
            <li><Link to="/derby">Derby</Link></li>
            <li><Link to="/injury">Injury Report</Link></li>
            <li><Link to="/records">Records</Link></li>
            <li><Link to="/past-winners">Past Winners</Link></li>
            <li><Link to="/memorial">Memorial</Link></li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/standings" element={<Standings />} />
          <Route path="/derby" element={<Derby injuredPlayers={injuredPlayers} />} />
          <Route path="/injury" element={<Injury />} />
          <Route path="/records" element={<Records />} />
          <Route path="/past-winners" element={<PastWinners />} />
          <Route path="/memorial" element={<Memorial />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;