import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Standings = () => {
  const [standings, setStandings] = useState([]);
  const [derbyData, setDerbyData] = useState({});

  useEffect(() => {
    const fetchStandings = async () => {
      const response = await axios.get('https://lw2pwsj2ye.execute-api.us-east-1.amazonaws.com/dev/api/standings');
      setStandings(response.data);
    };
    fetchStandings();
  }, []);

  useEffect(() => {
    const fetchDerbyData = async () => {
      const response = await axios.get('https://lw2pwsj2ye.execute-api.us-east-1.amazonaws.com/dev/api/derby');
      setDerbyData(response.data);
    };
    fetchDerbyData();
  }, []);

  const findPlayer = (playerName) => {
    for (const drafter in derbyData) {
      const player = derbyData[drafter].find(p => p.PlayerName === playerName);
      if (player) {
        return { ...player, drafter };
      }
    }
    return null;
  };

  const sideBets = [
    { player1: 'Ryan McMahon', drafter1: 'Douglas', player2: 'George Springer', drafter2: 'Anthony' },
    { player1: 'Hunter Renfroe', drafter1: 'Douglas', player2: 'Nelson Velazquez', drafter2: 'Barry' },
    { player1: 'Hunter Renfroe', drafter1: 'Mark', player2: 'Nelson Velazquez', drafter2: 'Barry' },
  ];

  return (
    <div className="container">
      <h1>Standings</h1>
      <table>
        <thead>
          <tr>
            <th>Place</th>
            <th>Team</th>
            <th>Total HR</th>
            <th>HRB</th>
          </tr>
        </thead>
        <tbody>
          {standings.map((team, index) => (
            <tr key={team.drafter}>
              <td>{index + 1}</td>
              <td>{team.drafter}</td>
              <td>{team.totalHR}</td>
              <td>{index === 0 ? '---' : standings[0].totalHR - team.totalHR}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h1>Side Bets</h1>
      <table>
        <thead>
          <tr>
            <th>Bettor 1</th>
            <th>Player 1</th>
            <th>HR</th>
            <th>Bettor 2</th>
            <th>Player 2</th>
            <th>HR</th>
          </tr>
        </thead>
        <tbody>
          {sideBets.map((bet, index) => {
            const player1 = findPlayer(bet.player1);
            const player2 = findPlayer(bet.player2);
            return (
              <tr key={index}>
                <td>{bet.drafter1}</td>
                <td>{bet.player1}</td>
                <td>{player1 ? player1.HR : 'N/A'}</td>
                <td>{bet.drafter2}</td>
                <td>{bet.player2}</td>
                <td>{player2 ? player2.HR : 'N/A'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Standings;