import React from 'react';

function PastWinners() {
  return (
    <div className="container">
      <h1>Past Winners</h1>
      <ul className="winner-list">
        <li>2023 - Anthony Mazzetta</li>
        <li>2022 - Paul Capelli</li>
        <li>2021 - Mark Redmond</li>
        <li>2020 - Brian Redmond</li>
        <li>2019 - Bob Kirk</li>
        <li>2018 - NO DERBY</li>
        <li>2017 - Barry Redmond</li>
        <li>2016 - Mark Redmond</li>
        <li>2015 - Doug Kirk</li>
        <li>2014 - Paul Capelli</li>
        <li>2013 - Leonard Alborn</li>
        <li>2012 - Barry Redmond</li>
        <li>2011 - Doug Kirk</li>
        <li>2010 - Bob Kirk</li>
        <li>2009 - Barry Redmond</li>
        <li>2008 - Mark Redmond</li>
        <li>2007 - Mark Redmond</li>
        <li>2006 - Barry Redmond</li>
        <li>2005 - Bob Kirk</li>
        <li>2004 - Larry Arthur</li>
        <li>2003 - Mark Redmond</li>
        <li>2002 - Paul Capelli</li>
        <li>2001 - Larry Arthur</li>
        <li>2000 - Larry Arthur</li>
        <li>1999 - Doug Kirk</li>
        <li>1998 - Barry Redmond</li>
        <li>1997 - Mark Redmond</li>
        <li>1996 - Doug Kirk</li>
        <li>1995 - Mark Redmond</li>
        <li>1994 - Bob Kirk</li>
        <li>1993 - Paul Capelli</li>
        <li>1992 - Ralph Fusco/Fran Callori</li>
        <li>1991 - Doug Kirk</li>
        <li>1990 - Ned Panfile</li>
        <li>1989 - Mark Redmond</li>
        <li>1988 - Doug Kirk</li>
        <li>1987 - Bob Kirk</li>
        <li>1986 - Leonard Alborn</li>
        <li>1985 - Leonard Alborn</li>
      </ul>
    </div>
  );
}

export default PastWinners;

