import React from 'react';

function RecordsPage() {
  return (
    <div className="container">
      <h1>Derby Records</h1>

      <h2>Cheers</h2>
      <table>
        <tbody>
          <tr>
            <td>Most Home Runs Year</td>
            <td>Barry Redmond</td>
            <td>2006</td>
            <td>388</td>
          </tr>
          <tr>
            <td>Most Home Runs April</td>
            <td>Barry Redmond</td>
            <td>2019</td>
            <td>84</td>
          </tr>
          <tr>
            <td>Most Home Runs May</td>
            <td>Barry Redmond</td>
            <td>2006</td>
            <td>75</td>
          </tr>
          <tr>
            <td>Most Home Runs June</td>
            <td>Larry Arthur</td>
            <td>2001</td>
            <td>72</td>
          </tr>
          <tr>
            <td>Most Home Runs July</td>
            <td>Barry Redmond</td>
            <td>1999</td>
            <td>79</td>
          </tr>
          <tr>
            <td>Most Home Runs August</td>
            <td>Barry Redmond</td>
            <td>1999</td>
            <td>78</td>
          </tr>
          <tr>
            <td>Most Home Runs September</td>
            <td>Leonard Alborn</td>
            <td>1999</td>
            <td>63</td>
          </tr>
          <tr>
            <td>Most Home Runs October</td>
            <td>Mark Redmond</td>
            <td>2001</td>
            <td>19</td>
          </tr>
        </tbody>
      </table>

      <h2>Jeers</h2>
      <table>
        <tbody>
          <tr>
            <td>Fewest Home Runs Year</td>
            <td>Freddie Main</td>
            <td>2022</td>
            <td>125</td>
          </tr>
          <tr>
            <td>Fewest Home Runs April</td>
            <td>Bob Morrow</td>
            <td>1991</td>
            <td>13</td>
          </tr>
          <tr>
            <td>Fewest Home Runs May</td>
            <td>Doug Kirk</td>
            <td>2021</td>
            <td>18</td>
          </tr>
          <tr>
            <td>Fewest Home Runs June</td>
            <td>Paul Capelli</td>
            <td>1991</td>
            <td>13</td>
          </tr>
          <tr>
            <td>Fewest Home Runs July</td>
            <td>Freddie Main</td>
            <td>2022</td>
            <td>13</td>
          </tr>
          <tr>
            <td>Fewest Home Runs August</td>
            <td>Mark Redmond</td>
            <td>1991</td>
            <td>14</td>
          </tr>
          <tr>
            <td>Fewest Home Runs September</td>
            <td>Mark Redmond</td>
            <td>1991</td>
            <td>12</td>
          </tr>
          <tr>
            <td>Fewest Home Runs October</td>
            <td>Held By Many</td>
            <td></td>
            <td>0</td>
          </tr>
        </tbody>
      </table>
      <h2>Best Pick By Round</h2>
      <table>
        <tbody>
          <tr>
            <td>Round</td>
            <td>Player</td>
            <td>Home Runs</td>
            <td>Team</td>
            <td>Year</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Barry Bonds (Giants)</td>
            <td>73</td>
            <td>Redmond, Mark</td>
            <td>2001</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Sammy Sosa (Cubs)</td>
            <td>66</td>
            <td>Redmond, Barry</td>
            <td>1998</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Ryan Howard (Phillies)</td>
            <td>58</td>
            <td>Redmond, Barry</td>
            <td>2006</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Kevin Mitchell (Giants)</td>
            <td>47</td>
            <td>Kirk, Bob</td>
            <td>1989</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Eugenio Suarez (Reds)</td>
            <td>49</td>
            <td>Kirk, Bob</td>
            <td>2019</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Prince Fielder (Brewers)</td>
            <td>50</td>
            <td>Capelli, Paul</td>
            <td>2007</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Mark McGwire (A’s)</td>
            <td>52</td>
            <td>Kirk, Doug</td>
            <td>1996</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Mark Reynolds (Diamondbacks)</td>
            <td>44</td>
            <td>Kirk, Bob</td>
            <td>2009</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Luis Gonzalez (Diamondbacks)</td>
            <td>57</td>
            <td>Arthur, Larry</td>
            <td>2001</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Cecil Fielder (Tigers)</td>
            <td>51</td>
            <td>Redmond, Barry</td>
            <td>1990</td>
          </tr>
          <tr>
            <td>11</td>
            <td>JD Martinez (Tigers-D’backs)</td>
            <td>45</td>
            <td>Kirk, Doug</td>
            <td>2017</td>
          </tr>
          <tr>
            <td>12</td>
            <td>Pete Alonso (Mets)</td>
            <td>53</td>
            <td>Redmond, Barry</td>
            <td>2019</td>
          </tr>
          <tr>
            <td>13</td>
            <td>Javier Lopez (Braves)</td>
            <td>23</td>
            <td>Redmond, Barry</td>
            <td>1997</td>
          </tr>
          <tr>
            <td>14</td>
            <td>Jason Giambi (A’s)</td>
            <td>20</td>
            <td>Redmond, Barry</td>
            <td>1997</td>
          </tr>
          <tr>
            <td>15</td>
            <td>Dave Nillson (Brewers)</td>
            <td>20</td>
            <td>Redmond, Barry</td>
            <td>1997</td>
          </tr>
        </tbody>
      </table>
      <h2>Worst Pick By Round</h2>
      <table>
        <tbody>
          <tr>
            <td>Round</td>
            <td>Player</td>
            <td>Home Runs</td>
            <td>Team</td>
            <td>Year</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Giancarlo Stanton (Yankees)/Glyber Torres (Yankees)</td>
            <td>3</td>
            <td>Redmond, Barry/Redmond, Brian</td>
            <td>2019/2020</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Ron Gant (unsigned)</td>
            <td>0</td>
            <td>Arthur, Larry</td>
            <td>1994</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Dave Henderson (A's)</td>
            <td>0</td>
            <td>Panfile, Ned</td>
            <td>1992</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Trey Mancini (Orioles)</td>
            <td>0</td>
            <td>Kirk, Douglas</td>
            <td>2020</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Vance Law (A's)</td>
            <td>0</td>
            <td>Morrow, B</td>
            <td>1991</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Josh Palacios (Blue Jays-Nationals)</td>
            <td>0</td>
            <td>Main, Freddie</td>
            <td>2022</td>
          </tr>
          <tr>
            <td>6</td>
            <td>John Jaha (A's)</td>
            <td>1</td>
            <td>Kirk, Doug</td>
            <td>2000</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Von Hayes (Phillies)</td>
            <td>0</td>
            <td>Kirk, Bob</td>
            <td>1991</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Chris Davis (Rangers)</td>
            <td>1</td>
            <td>Redmond, Mark</td>
            <td>2010</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Yordan Alvarez (Astros)</td>
            <td>1</td>
            <td>Wood, Jeremy</td>
            <td>2020</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Fernando Tatis, Jr. (Padres)</td>
            <td>0</td>
            <td>Main, Freddie</td>
            <td>2022</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Bob Horner (unsigned)</td>
            <td>0</td>
            <td>Alborn, Leonard</td>
            <td>1987</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Gary Maddox (Phillies)</td>
            <td>0</td>
            <td>Redmond, Barry</td>
            <td>1986</td>
          </tr>
          <tr>
            <td>11</td>
            <td>Mike Gallego (A’s)</td>
            <td>0</td>
            <td>Alborn, Leonard</td>
            <td>1995</td>
          </tr>
          <tr>
            <td>12</td>
            <td>Todd Pratt (Cubs)</td>
            <td>0</td>
            <td>Alborn, Leonard</td>
            <td>1995</td>
          </tr>
          <tr>
            <td>12</td>
            <td>Danny Tartabull (Phillies)</td>
            <td>0</td>
            <td>Redmond, Barry</td>
            <td>1997</td>
          </tr>
          <tr>
            <td>13</td>
            <td>Darryl Strawberry (Yankees)</td>
            <td>0</td>
            <td>Kirk, Doug</td>
            <td>1997</td>
          </tr>
          <tr>
            <td>14</td>
            <td>Kevin Mitchell (Reds)</td>
            <td>4</td>
            <td>Capelli, Paul</td>
            <td>1997</td>
          </tr>
          <tr>
            <td>15</td>
            <td>Todd Walker (Twins)</td>
            <td>3</td>
            <td>Alborn, Leonard</td>
            <td>1997</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default RecordsPage;
