import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Injury() {
  const [injuries, setInjuries] = useState([]);

  useEffect(() => {
    axios.get('https://lw2pwsj2ye.execute-api.us-east-1.amazonaws.com/dev/api/injuries')
      .then(response => {
        setInjuries(response.data);
      })
      .catch(error => console.error(error));
  }, []);

  return (
    <div className="container">
      <h1>Injury List</h1>
      <table>
        <thead>
          <tr>
            <th>Player Name</th>
            <th>Status</th>
            <th>Eligible Date</th>
            <th>Latest Update</th>
          </tr>
        </thead>
        <tbody>
          {injuries.map((injury, index) => (
            <tr key={index}>
              <td>{injury.playerName}</td>
              <td>{injury.status}</td>
              <td>{injury.eligibledate}</td>
              <td>{injury.latestUpdate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Injury;
