import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Derby.css';

// Function to calculate total HR excluding the two lowest
const calculateTotalHRExcludingTwoLowest = (players) => {
  if (players.length <= 8) {
    return players.reduce((acc, player) => acc + player.HR, 0);
  }
  const sortedPlayers = [...players].sort((a, b) => a.HR - b.HR);
  const top8Players = sortedPlayers.slice(2);
  return top8Players.reduce((acc, player) => acc + player.HR, 0);
};

// Function to calculate the lowest two HRs
const calculateLowestTwoHRs = (players) => {
  if (players.length <= 2) {
    return players.reduce((acc, player) => acc + player.HR, 0);
  }
  const sortedPlayers = [...players].sort((a, b) => a.HR - b.HR);
  return sortedPlayers[0].HR + sortedPlayers[1].HR;
};

// Function to calculate the total HR
const calculateTotalHR = (players) => {
  return players.reduce((acc, player) => acc + player.HR, 0);
};

function Derby({ injuredPlayers = [] }) { // Default value for injuredPlayers
  const [derbyData, setDerbyData] = useState({});

  useEffect(() => {
    axios.get('https://lw2pwsj2ye.execute-api.us-east-1.amazonaws.com/dev/api/derby')
      .then(response => {
        setDerbyData(response.data);
      })
      .catch(error => console.error('Error fetching derby data:', error));
  }, []);

  // Function to check if a player is injured
  const isPlayerInjured = (playerName) => {
    return injuredPlayers.some(player => player.playerName === playerName);
  };

  // Define the custom order of drafters
  const drafterOrder = ['Jeremy', 'Paul', 'Bob', 'Anthony', 'Brian', 'Doug', 'Freddie', 'Douglas', 'Hoobie', 'Barry', 'Mark'];

  return (
    <div className="container">
      <h1>Derby</h1>
      <div className="derby-table">
        <table>
          <thead>
            <tr>
              {drafterOrder.map(drafter => (
                <th key={drafter}>
                  {drafter} <br /> {calculateTotalHRExcludingTwoLowest(derbyData[drafter]?.sort((a, b) => a.rank - b.rank) || [])}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[...Array(10)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {drafterOrder.map(drafter => (
                  <td key={`${drafter}-${rowIndex}`}>
                    {derbyData[drafter] && derbyData[drafter][rowIndex] ? (
                      <div className={`player-info ${isPlayerInjured(derbyData[drafter][rowIndex].PlayerName) ? 'injured' : ''}`}>
                        <div className="player-name">{derbyData[drafter][rowIndex].PlayerName}</div>
                        <div className="team-name">{derbyData[drafter][rowIndex].TeamName}</div>
                        <div className="hr-count">{derbyData[drafter][rowIndex].HR}</div>
                      </div>
                    ) : null}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="summary-table">
        <h2>Summary</h2>
        <table>
          <thead>
            <tr>
              <th>Summary</th>
              {drafterOrder.map(drafter => (
                <th key={drafter}>{drafter}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total HRs</td>
              {drafterOrder.map(drafter => (
                <td key={drafter}>
                  <div className="totals">
                    {calculateTotalHR(derbyData[drafter]?.sort((a, b) => a.rank - b.rank) || [])}
                  </div>
                </td>
              ))}
            </tr>
            <tr>
              <td>Lowest 2 HRs</td>
              {drafterOrder.map(drafter => (
                <td key={drafter}>
                  <div className="totals">
                    {calculateLowestTwoHRs(derbyData[drafter]?.sort((a, b) => a.rank - b.rank) || [])}
                  </div>
                </td>
              ))}
            </tr>
            <tr>
              <td>Used HRs</td>
              {drafterOrder.map(drafter => (
                <td key={drafter}>
                  <div className="totals">
                    {calculateTotalHRExcludingTwoLowest(derbyData[drafter]?.sort((a, b) => a.rank - b.rank) || [])}
                  </div>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Derby;
